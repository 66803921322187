export default {
  sidebar: {
    home: '首页',
    stylegen: '样式生成器',
    help: '帮助',
    plugins: '插件',
    links: '常用链接',
    projectAddress: '项目地址',
    discussion: '反馈 / 交流',
    documentation: '文档',
    mall: 'B站商店',
    giftRecordOfficial: '打赏记录',
  },
  home: {
    roomIdEmpty: '房间ID不能为空',
    roomIdInteger: '房间ID必须为正整数',
    authCodeEmpty: '身份码不能为空',
    authCodeFormatError: '身份码格式错误',

    unavailableWhenUsingAuthCode: '已过时，使用身份码时不可用',
    disabledByServer: '已被服务器禁用',

    general: '常规',
    useAuthCodeWarning: '请优先使用身份码，否则无法显示头像和昵称',
    room: '房间',
    roomId: '房间ID（需要在下面扫码登录，建议小号登录）',
    authCode: '身份码',
    howToGetAuthCode: '如何获取身份码',
    showDanmaku: '显示弹幕',
    showGiftName: '显示礼物名',
    mergeSimilarDanmaku: '合并相似弹幕',
    
    maxNumber: '最大弹幕数',

    block: '屏蔽',
    giftDanmaku: '屏蔽礼物弹幕',
    blockLevel: '屏蔽用户等级低于',
    informalUser: '屏蔽非正式会员',
    unverifiedUser: '屏蔽未绑定手机用户',
    blockKeywords: '屏蔽关键词',
    onePerLine: '一行一个',
    blockUsers: '屏蔽用户',
    blockMedalLevel: '屏蔽当前直播间勋章等级低于',

    advanced: '高级',
    relayMessagesByServer: '通过服务器转发消息',
    relayMessagesByServerTip: '开启时的消息路径：B站服务器 -> blivechat服务器 -> 你的浏览器。部分高级功能需要开启这个。推荐只在本地使用blivechat时开启，而通过远程服务器使用时不开启',
    autoTranslate: '自动翻译弹幕到日语',
    requiresRelayMessagesByServer: '需要通过服务器转发消息',
    giftUsernamePronunciation: '标注打赏用户名读音',
    dontShow: '不显示',
    pinyin: '拼音',
    kana: '日文假名',
    importPresetCss: '导入服务器预设CSS',
    importPresetCssTip: '自动导入服务器的CSS文件：data/custom_public/preset.css',

    emoticon: '自定义表情',
    emoticonKeyword: '替换关键词',
    emoticonUrl: 'URL',
    operation: '操作',
    addEmoticon: '添加表情',
    emoticonFileTooLarge: '文件尺寸太大，最大1MB',

    urlTooLong: '房间URL太长了，会被直播姬截断（OBS不会）',
    roomUrlUpdated: '房间URL已更新，记得重新复制',
    roomUrl: '房间URL',
    enterRoom: '进入房间',
    enterTestRoom: '进入测试房间',
    exportConfig: '导出配置',
    importConfig: '导入配置',

    failedToParseConfig: '配置解析失败：',
    openSpecificTutorial: '打开对应教程',
    showInteractWordEnter: '显示进房互动',
    showInteractWordFollow: '显示关注互动',
    showInteractWordShare: '显示分享互动',
    showSuperchat: '显示醒目留言(SC)',
    showNewMember: '显示新舰长',
    showGift: '显示礼物',
    showGiftInfo: '显示礼物信息',
    mergeGift: '合并礼物',
    minGiftPrice: '显示高于价格打赏',
    minTickerPrice: '停驻高于价格打赏',
    danmakuAtBottom: '弹幕居下',
    tickerAtButtom: '底部显示SC固定栏',
    logout: '登出',
    startLogin: '开始登录',
    isLoginTrue: '已登录',
    isLoginFalse: '未登录',
    useSecondaryScanCode: '为确保账号安全，请使用小号进行扫码登录，注意：不登录盲盒姬显示昵称带* 不显示完整昵称，应该不影响使用吧~ ',
    useLoaderUrl: '使用加载器',
    minGiftPricePlaceholder: '(元)',

  },
  stylegen: {
    general: '通用',
    legacy: '经典',
    lineLike: '仿微信',

    light: '明亮',
    dark: '黑暗',

    copyBlockCss: '左键点击复制当前属性CSS样式',

    global: '全局',
    danmakuAtBottom: '弹幕从底部出现',
    tickerAtButtom: '底部显示SC固定栏',
    
    outlines: '描边',
    showOutlines: '显示描边',
    outlineSize: '描边尺寸',
    outlineColor: '描边颜色',

    avatars: '头像',
    showAvatars: '显示头像',
    avatarSize: '头像尺寸',

    // FIXME: 注意不要删除其他命名，其他命名仍用于其他tab页面
    // TODO: 一般消息
    textMessage: '一般消息',

    textMessageAvatar: '一般消息头像',
    textMessageShowAvatar: '显示头像',
    textMessageAvatarSize: '头像尺寸',
    
    textMessageUserName: '一般消息用户名',
    textMessageShowUserName: '显示用户名',
    textMessageUserNameFont: '用户名字体',
    textMessageUserNameFontSize: '用户名字体尺寸',

    textMessageUserNameOutlineSize: '用户名描边尺寸',
    textMessageUserNameOutlineColor: '用户名描边颜色',

    textMessageUserNameLineHeight: '用户名行高（0为默认）',
    textMessageUserNameColor: '一般用户颜色',
    textMessageUserNameRandomColor: '随机用户名颜色',
    textMessageUserNameOwnerColor: '主播颜色',
    textMessageUserNameModeratorColor: '房管颜色',
    textMessageUserNameMember3Color: '舰长颜色',
    textMessageUserNameMember2Color: '提督颜色',
    textMessageUserNameMember1Color: '总督颜色',
    textMessageShowColon: '用户名后显示冒号',
    
    textMessageBadges: '一般消息勋章',
    textMessageShowBadges: '显示勋章',
    textMessageBadgesSize: '勋章大小',

    textMessageMedal: '一般消息粉丝勋章',
    textMessageShowMedal: '显示粉丝勋章',
    textMessageShowOnlyOwnerMedal: '只显示直播主勋章',
    textMessageShowMedalName: '显示粉丝勋章名',
    textMessageShowMedalLevel: '显示粉丝勋章等级',
    textMessageMedalFontSize: '粉丝勋章字体大小',
    textMessageMedalLineHeight: '显示粉丝勋章行高',

    textMessageContent: '一般消息内容',
    textMessageContentColor: '内容颜色',
    textMessageContentFont: '内容字体',
    textMessageContentFontSize: '内容字体尺寸',
    textMessageContentLineHeight: '内容字体行高（0为默认）',

    textMessageContentOutlineSize: '内容描边尺寸',
    textMessageContentOutlineColor: '内容描边颜色',

    textMessageOnNewLine: '内容在新行显示',
    textMessageMergeSameUser: '合并同用户内容为组',
    textMessageGroupBlockPadding: '内容组内间距',
    
    textMessageBg: '一般消息背景',
    textMessageUseBarsInsteadOfBg: '用条代替消息背景',
    textMessageMessageBgShadow: '消息背景阴影',
    textMessageMessageBgColor: '消息背景色',
    textMessageOwnerMessageBgColor: '主播消息背景色',
    textMessageModeratorMessageBgColor: '房管消息背景色',
    textMessageMember3MessageBgColor: '舰长消息背景色',
    textMessageMember2MessageBgColor: '提督消息背景色',
    textMessageMember1MessageBgColor: '总督消息背景色',
    
    textMessageTime: '一般消息时间',
    textMessageTimeShow: '显示消息时间',
    textMessageTimeShowRight: '在右侧显示时间',
    textMessageTimeColor: '时间颜色',
    textMessageTimeFont: '时间字体',
    textMessageTimeFontSize: '时间字体尺寸',
    textMessageTimeLineHeight: '时间字体行高（0为默认）',
    textMessageTimeOutlineSize: '时间描边尺寸',
    textMessageTimeOutlineColor: '时间描边颜色',

    textMessageEmoticon: '表情',
    textMessageOfficialSmallEmojiSize: 'B站官方小表情大小',
    textMessageOfficialGeneralEmojiSize: 'B站官方通用表情大小',
    textMessageStreamerEmojiSize: '主播房间表情大小',
    textMessagePersonalEmojiSize: '个人购买表情大小',
    
    textMessageEmoticonInlineBorderRadius: '同行表情圆角',
    textMessageEmoticonBlockBorderRadius: '换行表情圆角',

    textMessageAnimation: '一般消息动画',
    textMessageAnimateIn: '进入动画',
    textMessageFadeInTime: '淡入时间（毫秒）',
    textMessageAnimateOut: '移除旧消息',
    textMessageFadeOutTime: '淡出时间（毫秒）',
    textMessageAnimateOutWaitTime: '移除前等待时间（秒）',
    textMessageSlide: '滑动',
    textMessageReverseSlide: '反向滑动',

    // TODO: 打赏消息（SC、礼物）
    paidMessage: '打赏消息（SC、礼物）',

    paidMessageAvatar: '打赏消息头像',
    paidMessageShowAvatar: '显示头像',
    paidMessageAvatarSize: '头像尺寸',

    paidMessageBg: '打赏消息背景',
    paidMessageMessageBgShadow: '打赏消息背景阴影',

    paidMessageFirstLine: '打赏消息（SC、礼物）第一行',
    paidMessageFirstLineColor: '第一行颜色',
    paidMessageFirstLineFont: '第一行字体',
    paidMessageFirstLineFontSize: '第一行字体尺寸',
    paidMessageFirstLineLineHeight: '第一行行高（0为默认）',
    paidMessageFirstLineOutlineSize: '第一行描边尺寸',
    paidMessageFirstLineOutlineColor: '第一行描边颜色',

    paidMessageSecondLine: '打赏消息（SC、礼物）第二行',
    paidMessageSecondLineColor: '第二行颜色',
    paidMessageSecondLineFont: '第二行字体',
    paidMessageSecondLineFontSize: '第二行字体尺寸',
    paidMessageSecondLineLineHeight: '第二行行高（0为默认）',
    paidMessageSecondLineOutlineSize: '第二行描边尺寸',
    paidMessageSecondLineOutlineColor: '第二行描边颜色',
    
    paidMessageContentLine: '打赏消息（SC、礼物）内容行',
    paidMessageContentLineColor: '内容行颜色',
    paidMessageContentLineFont: '内容行字体',
    paidMessageContentLineFontSize: '内容行字体尺寸',
    paidMessageContentLineLineHeight: '内容行行高（0为默认）',
    paidMessageContentLineOutlineSize: '内容行描边尺寸',
    paidMessageContentLineOutlineColor: '内容行描边颜色',

    paidMessageAnimation: '打赏消息动画',
    paidMessageAnimateIn: '进入动画',
    paidMessageFadeInTime: '淡入时间（毫秒）',
    paidMessageAnimateOut: '移除旧消息',
    paidMessageFadeOutTime: '淡出时间（毫秒）',
    paidMessageAnimateOutWaitTime: '移除前等待时间（秒）',
    paidMessageSlide: '滑动',
    paidMessageReverseSlide: '反向滑动',
    
    // TODO: 上舰消息
    membershipMessage: '上舰消息',

    membershipMessageAvatar: '上舰消息头像',
    membershipMessageShowAvatar: '显示头像',
    membershipMessageAvatarSize: '头像尺寸',

    membershipMessageBg: '上舰消息背景',
    membershipMessageShowBg: '显示上舰背景',
    membershipMessageMessageBgShadow: '消息背景阴影',
    membershipMessageMember3MessageBgColor: '舰长背景颜色',
    membershipMessageMember2MessageBgColor: '提督背景颜色',
    membershipMessageMember1MessageBgColor: '总督背景颜色',

    membershipMessageFirstLine: '上舰消息第一行',
    membershipMessageFirstLineFont: '第一行字体',
    membershipMessageFirstLineFontSize: '第一行字体尺寸',
    membershipMessageFirstLineLineHeight: '第一行行高（0为默认）',
    membershipMessageFirstLineColor: '第一行颜色',
    membershipMessageFirstLineOutlineSize: '第一行描边尺寸',
    membershipMessageFirstLineOutlineColor: '第一行描边颜色',

    membershipMessageSecondLine: '上舰消息第二行',
    membershipMessageSecondLineFont: '第二行字体',
    membershipMessageSecondLineFontSize: '第二行字体尺寸',
    membershipMessageSecondLineLineHeight: '第二行行高（0为默认）',
    membershipMessageSecondLineColor: '第二行颜色',
    membershipMessageSecondLineOutlineSize: '第二行描边尺寸',
    membershipMessageSecondLineOutlineColor: '第二行描边颜色',

    membershipMessageAnimation: '上舰消息动画',
    membershipMessageAnimateIn: '进入动画',
    membershipMessageFadeInTime: '淡入时间（毫秒）',
    membershipMessageAnimateOut: '移除旧消息',
    membershipMessageFadeOutTime: '淡出时间（毫秒）',
    membershipMessageAnimateOutWaitTime: '移除前等待时间（秒）',
    membershipMessageSlide: '滑动',
    membershipMessageReverseSlide: '反向滑动',

    // TODO: 互动消息
    interactMessage: '互动消息（进入、关注直播间）',

    interactMessageAvatar: '互动消息头像',
    interactMessageShowAvatar: '显示头像',
    interactMessageAvatarSize: '头像尺寸',
    
    interactMessageUserName: '互动消息用户名',
    interactMessageShowUserName: '显示用户名',
    interactMessageUserNameFont: '用户名字体',
    interactMessageUserNameFontSize: '用户名字体尺寸',
    interactMessageUserNameLineHeight: '用户名行高（0为默认）',

    interactMessageUserNameOutlineSize: '用户名描边尺寸',
    interactMessageUserNameOutlineColor: '用户名描边颜色',

    interactMessageUserNameRandomColor: '随机用户名颜色',
    interactMessageUserNameColor: '一般用户颜色',
    interactMessageUserNameOwnerColor: '主播颜色',
    interactMessageUserNameModeratorColor: '房管颜色',
    interactMessageUserNameMember3Color: '舰长颜色',
    interactMessageUserNameMember2Color: '提督颜色',
    interactMessageUserNameMember1Color: '总督颜色',
    interactMessageShowColon: '用户名后显示冒号',
    
    interactMessageBadges: '互动消息勋章',
    interactMessageBadgesShow: '显示勋章',
    interactMessageBadgesSize: '勋章大小',

    interactMessageMedal: '互动消息粉丝勋章',
    interactMessageMedalShow: '显示粉丝勋章',
    interactMessageMedalShowOnlyOwner: '只显示直播主勋章',
    interactMessageMedalShowName: '显示粉丝勋章名',
    interactMessageMedalShowLevel: '显示粉丝勋章等级',
    interactMessageMedalFontSize: '粉丝勋章字体尺寸',
    interactMessageMedalLineHeight: '粉丝勋章行高（0为默认）',

    interactMessageContent: '互动消息内容',
    interactMessageContentColor: '内容颜色',
    interactMessageContentFont: '内容字体',
    interactMessageContentFontSize: '内容字体尺寸',
    interactMessageContentLineHeight: '内容字体行高（0为默认）',
    interactMessageContentOutlineSize: '内容描边尺寸',
    interactMessageContentOutlineColor: '内容描边颜色',
    
    interactMessageBg: '互动消息背景',
    interactMessageUseBarsInsteadOfBg: '用条代替消息背景',
    interactMessageMessageBgShadow: '消息背景阴影',
    interactMessageMessageBgColor: '消息背景色',
    interactMessageOwnerMessageBgColor: '主播消息背景色',
    interactMessageModeratorMessageBgColor: '房管消息背景色',
    interactMessageMember3MessageBgColor: '舰长消息背景色',
    interactMessageMember2MessageBgColor: '提督消息背景色',
    interactMessageMember1MessageBgColor: '总督消息背景色',
    
    interactMessageTime: '互动消息时间',
    interactMessageTimeShow: '显示消息时间',
    interactMessageTimeShowRight: '在右侧显示时间',
    interactMessageTimeColor: '时间颜色',
    interactMessageTimeFont: '时间字体',
    interactMessageTimeFontSize: '时间字体尺寸',
    interactMessageTimeLineHeight: '时间字体行高（0为默认）',
    interactMessageTimeOutlineSize: '时间描边尺寸',
    interactMessageTimeOutlineColor: '时间描边颜色',

    interactMessageAnimation: '互动消息动画',
    interactMessageAnimateIn: '进入动画',
    interactMessageFadeInTime: '淡入时间（毫秒）',
    interactMessageAnimateOut: '移除旧消息',
    interactMessageFadeOutTime: '淡出时间（毫秒）',
    interactMessageAnimateOutWaitTime: '移除前等待时间（秒）',
    interactMessageSlide: '滑动',
    interactMessageReverseSlide: '反向滑动',

    // TODO: 全局内边距
    globalPadding: '全局内边距',
    globalPaddingTop: '全局内边距-上侧',
    globalPaddingBottom: '全局内边距-下侧',
    globalPaddingLeft: '全局内边距-左侧',
    globalPaddingRight: '全局内边距-右侧',

    // TODO: 全局外边距
    globalMargin: '全局外边距',
    globalMarginTop: '全局外边距-上侧',
    globalMarginBottom: '全局外边距-下侧',
    globalMarginLeft: '全局外边距-左侧',
    globalMarginRight: '全局外边距-右侧',

    // TODO: 全局圆角大小
    globalBorderRadius: '全局圆角大小',
    globalBorderRadiusTopLeft: '全局圆角大小-左上',
    globalBorderRadiusTopRight: '全局圆角大小-右上',
    globalBorderRadiusBottomLeft: '全局圆角大小-左下',
    globalBorderRadiusBottomRight: '全局圆角大小-右下',

    // TODO: 固定栏消息
    Ticker: '打赏固定栏',
    TickerShowTicker: '显示打赏固定栏',
    TickerShowThingsOtherThanTicker: '显示打赏固定栏之外的内容',

    userNames: '用户名',
    showUserNames: '显示用户名',
    randomUserNamesColor: '随机名字颜色',
    font: '字体',
    fontSize: '字体尺寸',
    lineHeight: '行高（0为默认）',
    normalColor: '普通颜色',
    ownerColor: '主播颜色',
    moderatorColor: '房管颜色',
    memberColor: '舰长颜色',
    showBadges: '显示舰队勋章',
    showColon: '用户名后显示冒号',
    badgesSize: '勋章大小',

    medal: '粉丝勋章(牌子)',
    showMedal: '显示粉丝勋章',
    showOnlyOwnerMedal: '只显示直播主勋章',
    showMedalName: '显示粉丝勋章名',
    showMedalLevel: '显示粉丝勋章等级',

    emoticonSize: '表情大小',
    emoticonInlineBorderRadius: '同行表情圆角',
    emoticonBlockBorderRadius: '换行表情圆角',

    messages: '消息',
    color: '颜色',
    onNewLine: '在新行显示',
    mergeSameUser: '合并同用户消息为组',
    groupBlockPadding: '消息组内消息的间距',
    

    time: '时间',
    showTime: '显示时间',
    showTimeRight: '在右侧显示时间',

    backgrounds: '背景',
    bgColor: '全局背景色',
    useBarsInsteadOfBg: '用条代替消息背景',
    messageBgColor: '消息背景色',
    ownerMessageBgColor: '主播消息背景色',
    moderatorMessageBgColor: '房管消息背景色',
    memberMessageBgColor: '舰长消息背景色',

    scAndNewMember: '打赏、舰长',
    firstLineFont: '第一行字体',
    firstLineFontSize: '第一行字体尺寸',
    firstLineLineHeight: '第一行行高（0为默认）',
    firstLineColor: '第一行颜色',
    secondLineFont: '第二行字体',
    secondLineFontSize: '第二行字体尺寸',
    secondLineLineHeight: '第二行行高（0为默认）',
    secondLineColor: '第二行颜色',
    scContentLineFont: 'Super Chat内容字体',
    scContentLineFontSize: 'Super Chat内容字体尺寸',
    scContentLineLineHeight: 'Super Chat内容行高（0为默认）',
    scContentLineColor: 'Super Chat内容颜色',
    showNewMemberBg: '显示新舰长背景',
    showScTicker: '显示Super Chat固定栏',
    showOtherThings: '显示Super Chat固定栏之外的内容',

    animation: '动画',
    animateIn: '进出动画',
    fadeInTime: '淡入时间（毫秒）',
    animateOut: '移除旧消息',
    animateOutWaitTime: '移除前等待时间（秒）',
    fadeOutTime: '淡出时间（毫秒）',
    slide: '滑动',
    reverseSlide: '反向滑动',
    playAnimation: '播放动画',

    result: '结果',
    copy: '复制',
    resetConfig: '恢复默认设置'
  },
  help: {
    help: '帮助',
    p1_1: '1. 从这个页面复制身份码：',
    p1_2: '。注意：不要刷新身份码，除非你的身份码泄露了，因为刷新身份码会使旧的身份码失效',
    p2: '2. 把身份码输入到首页的房间配置，复制房间URL',
    p3: '3. 使用样式生成器生成样式，复制CSS',
    p4: '4. 在OBS中添加浏览器源',
    p5: '5. URL处输入之前复制的房间URL，自定义CSS处输入之前复制的CSS'
  },
  room: {
    fatalErrorOccurred: '发生了一个致命错误，请手动刷新页面以重新连接'
  },
  chat: {
    moderator: '管理员',
    guardLevel1: '总督',
    guardLevel2: '提督',
    guardLevel3: '舰长',
    sendGift: '赠送 {giftName}x{num}',
    membershipTitle: '新会员',
    tickerMembership: '会员'
  },
  plugins: {
    plugins: '插件',
    help: '帮助',
    helpContent: `\
<p>插件可以给blivechat添加更多功能，比如消息日志、语音播报、点歌等。插件可能由第三方作者开发，其安全性和质量由插件作者负责。
  你可以在<a target="_blank" href="https://github.com/xfgryujk/blivechat/discussions/categories/%E6%8F%92%E4%BB%B6"
  >GitHub Discussions</a>获取一些已发布的插件</p>
<p>插件安装方法：把解压后的插件目录放到“data/plugins”目录，然后重启blivechat</p>
<p>注意事项：大部分插件需要开启“通过服务器转发消息”，并且连接到房间，才能接收消息</p>
<p><a target="_blank" href="https://github.com/xfgryujk/blivechat/wiki/%E6%8F%92%E4%BB%B6%E7%B3%BB%E7%BB%9F">插件开发文档</a></p>
`,
    author: '作者：',
    disabledByServer: '已被服务器禁用',
    admin: '管理',
    connected: '已连接',
    unconnected: '未连接',
  },
}
